import React,{useEffect} from 'react';
import Header from '../components/Header';
import { Row, Col } from 'react-bootstrap';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import Blog1 from '../images/Blog11.jpg'
import Blog2 from '../images/Blog22.jpg'
import Blog33 from '../images/Blog33.jpg'
import Blog44 from '../images/Blog44.jpg'
import Blog55 from '../images/Blog55.jpg'
import Footer from  '../components/FooterMain'



const Blogs = () => {
  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);
  
  return (
    <div style={{backgroundColor:'#efefef'}}>
      <Header />
      <div className='text-center mb-2' style={{background: 'linear-gradient(to top, #efefef 0%, #edf4fe 74%, #ffe7e5 100%)',position: 'relative',padding: '60px 0 80px',overflow: 'hidden'}}>
        <div style={{justifyContent:'center', display:'flex',alignItems:'center'}}>
            <h1 style={{fontSize: '55px',color: '#000',lineHeight: '1.2',letterSpacing: '-2px', fontFamily:'Lato, sans-serif'}}>Our Blog</h1>
        </div>
      </div>

      <section className='container'>
        <Row>
          <Col md={4}>
      <Link to='/how_to_choose_moving_companies_for_stress-free_Move' style={{textDecoration:'none'}}>
        <Card className='fixed-height-card'>
              <CardMedia className='makemyimage' sx={{ height: 140 }}
                image={Blog1}
                title="green iguana"/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" className='blogcardHeader'>
                How to Choose Moving Companies for Stress-free Move </Typography>
                <Typography variant="body2" color="text.secondary">
                  When it comes to moving, the process can be incredibly stressful and time-consuming. Packing up your entire household, transporting it to your new home...
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Read More</Button>
              </CardActions>
            </Card>
            </Link>    
          </Col>


          <Col md={4}>
          <Link to='/best_long-distance_moving_companies_in_United Kingdom' style={{textDecoration:'none'}}>
            <Card className='fixed-height-card'>
              <CardMedia
                className='makemyimage'
                sx={{ height: 140 }}
                image={Blog2}
                title="green iguana"  />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" className='blogcardHeader'>
                  Best Long-Distance Moving Companies in United Kingdom
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Relocating is a complex process that requires careful planning and effort. From setting your arrival date to organizing your move, hiring movers, and packing your belongings, every stage can be stressful.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Read More</Button>
              </CardActions>
            </Card>
            </Link>
          </Col>
          <Col md={4}>
          <Link to='/cheap_moving_companies_in_United Kingdom' style={{textDecoration:'none'}}>
            <Card className='fixed-height-card'>
              <CardMedia
                className='makemyimage'
                sx={{ height: 140 }}
                image={Blog33}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" className='blogcardHeader'>
                  Cheap Moving Companies in United Kingdom
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Moving can be a stressful and expensive process, but finding a cheap moving company in United Kingdom can help ease both your mind and your wallet. Whether you're relocating within the same city or moving across the country, here are some tips and recommendations to help you find affordable movers without compromising on quality.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Read More</Button>
              </CardActions>
            </Card>
            </Link>
          </Col>

    {/* ---------  Set-2 ------------  */}
          <Col className='my-5' md={4}>
          <Link to='/top10_moving_companies_in_United Kingdom' style={{textDecoration:'none'}}>
            <Card className='fixed-height-card'>
              <CardMedia className='makemyimage' sx={{ height: 140 }}
                image={Blog44}
                title="green iguana"  />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" className='blogcardHeader'>
                Top 10 Moving Companies In United Kingdom
                 </Typography>
                <Typography variant="body2" color="text.secondary">
                Moving from city to city or province to province can be a complex process. Luckily, many long-distance moving companies in United Kingdom can help make the process smoother.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Read More</Button>
              </CardActions>
            </Card>
            </Link>
          </Col>
          <Col className='my-5' md={4}>
          <Link to='/do_moving_company_unbox_boxes_and_arrange_household_items' style={{textDecoration:'none'}}>
            <Card className='fixed-height-card'>
              <CardMedia
                className='makemyimage'
                sx={{ height: 140 }}
                image={Blog55}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" className='blogcardHeader'>
                Do Moving company Unbox Boxes and arrange Household Items?
                 </Typography>
                <Typography variant="body2" color="text.secondary">
                When it comes to moving, the process can be incredibly stressful and time-consuming. Packing up your entire household, transporting it to your new home, and then unpacking and arranging everything can feel overwhelming.                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Read More</Button>
              </CardActions>
            </Card>
            </Link>
          </Col>
        </Row>
      </section>
      <Footer/>
    </div>
  );
}

export default Blogs;
