import React,{useEffect} from 'react'
import Header from '../components/Header'
import {Link} from 'react-router-dom';
import ContactBannerImg from '../images/storageServices.webp'
import serivePlan from '../images/ResidentialStorag.jpg'
import serivePlan2 from '../images/JankStorage2.jpg'
import serivePlan3 from '../images/JankStorage.jpg'
import serivePlan4 from '../images/CommercialStorage.jpg'
import serivePlan8 from '../images/Long-TermStorage.jpg'
import serivePlan6 from '../images/SecureFacilities.jpg'
import welcometruck from '../images/welcome-truck.png'
import {Fade,Zoom} from 'react-reveal';
import {Row,Col,Card} from 'react-bootstrap';
import { AiFillSafetyCertificate } from "react-icons/ai";
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import Avatar from '@mui/material/Avatar';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Footer from '../components/FooterMain'
import MoreService from '../components/MoreService'
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import {Helmet} from 'react-helmet'


const StorageServices = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);
  
  useEffect(() => {
    // Function to get the IP address
    const fetchIpAddress = async () => {
      try {
        // Fetch the IP address from IPify API
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const ipAddress = data.ip;

        // Send the IP address to your API
        await sendIpAddressToApi(ipAddress);

      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    // Function to send the IP address to your API
    const sendIpAddressToApi = async (ipAddress) => {
      try {
        const response = await fetch('https://allukmovers.com/acvlbackend/api/ipaddress', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipaddress: ipAddress }),
        });

        if (response.ok) {
          console.log('IP address sent successfully');
        } else {
          console.error('Failed to send IP address');
        }
      } catch (error) {
        console.error('Error sending IP address:', error);
      }
    };

    // Call the function to fetch IP and send it to your API
    fetchIpAddress();

  }, []); // Empty dependency array ensures this runs only once when the component mounts


  return (
    <div>
            <Helmet>
        <title>All United Kingdom Van Lines| Expert Storage Services Solutions | moving company | award-winning moversAll United Kingdom Van Lines| Your Trusted Local Moving Company/Movers near by| Guaranteed Highest Quality Service</title>
        <meta name="description" content="We have 25 years of experience. Secure Space offers reliable and secure storage solutions to meet your needs. Experience peace of mind knowing your items are in good hands with Secure Space." />
      </Helmet>
        <Header/>
        {/* Bannar */}
        <div>
          <div style={{position: 'relative', height:'340px'}}>
          <div style={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%',zIndex: 1 }}>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000080',}} />
          <div className='bgServiceImage' style={{backgroundImage: `url(${ContactBannerImg})`,backgroundRepeat: 'no-repeat',height: '340px',backgroundSize: 'cover',backgroundPosition: 'center',backgroundPositionY: '-137px',}}>
          <Zoom right>
          <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
              <h1 className='serviceTitle' style={{ color: '#ffffff', marginLeft:'30px', fontFamily:'Lato, sans-serif',fontSize: 'xxx-large',fontWeight: '600' }}>STORAGE SERVICES</h1>
              <div className='serviceSubTitle' style={{marginLeft:'37px', display:'flex', marginBottom:'50px' }}>
              <Link to='/' style={{textDecoration:"none"}}><h6 style={{color:'#FC4243', marginRight:'10px', cursor:'pointer',fontFamily:'Lato, sans-serif'}}>Home</h6></Link>
              <h6 style={{color: '#ffffff',fontFamily:'Lato, sans-serif'}}>/ Storage Services</h6>
              </div>
          </div></Zoom>
          </div>
        </div>
          </div>
        </div>
         {/* Circle-Heading */}
        <div className='ms-4 mt-5 pb-4'>
          <Fade right>
          <hr className='hrService'/>
          <h2 style={{fontFamily: 'Lato, sans-serif'}}>Storage Services in the UK</h2>
          <h5 style={{lineHeight: '1.6',marginRight: '7px',fontFamily:'Lato, sans-serif',fontWeight: '400'}}>At All UK Storage Solutions, we understand that finding safe, flexible, and affordable storage options is essential during a move, renovation, or when you need extra space. Our comprehensive storage services provide the perfect solution for households, businesses, and students across the UK. Whether you need short-term or long-term storage, we have a variety of units designed to keep your belongings secure and accessible.</h5>
          </Fade>
        </div>
        {/* Circle */}
        <div className='container my-5'>
        <Fade left>
          <Row className=''>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid #0A249D',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center',}}>
              <AiFillSafetyCertificate size={90}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Protected and Secure</h5>
            </Col>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid #0A249D',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
              <WarehouseIcon style={{fontSize:'85px', marginBottom:'10px'}}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Adaptable Storage Solutions</h5>
            </Col>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid #0A249D',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
              <ThunderstormIcon style={{fontSize:'85px', marginBottom:'10px'}}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Climate Controlled Units</h5>
            </Col>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid #0A249D',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
              <SettingsAccessibilityIcon style={{fontSize:'85px', marginBottom:'10px'}}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>24 x 7 Accessibility Climate alternate</h5>
            </Col>
          </Row></Fade>
        </div>
        {/* Detailed List of Services Included */}
        <div className='pt-4'>
        <Fade left>
          <div className='container'>
          <hr className='hrService '/>
          <h2 style={{fontFamily: 'Lato, sans-serif', marginRight: '1px'}}>Detailed List of Services Included</h2>
          </div></Fade>
          <div className='px-3 pt-4'>
          <Fade right>
            <Row className='pb-5'>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan} style={{height:'191px'}} />
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Residential Storage Solutions</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Reliable and secure options for storing household items locally or across the state during life changes, with both short-term and long-term plans available.             
                        </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan2}  style={{height:'191px'}}/>
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Commercial Storage Space</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Secure facilities throughout the UK for businesses to store inventory, equipment, and furniture, offering 24/7 access and climate control.</Card.Text>                 </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan3}  style={{height:'191px',}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Temporary Storage for Transitional Needs</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Short-term storage units in the UK for transitional periods such as moves, staging, and renovations. Flexible access and affordable pricing options.
                     </Card.Text>
                    </Card.Body>
                </Card>
              </Col>
            </Row> 
            <Row className='pb-5'>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan4} style={{height:'191px'}} />
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Extended Long-Term Storage Solutions</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Cost-effective long-term storage facilities in the UK for safeguarding valuable items, featuring climate control and enhanced security.</Card.Text></Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan6}  style={{height:'191px'}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Top-Level Protection with Advanced Security</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Facilities offering personalized access, sensors, CCTV, and backup power to ensure 24/7 protection of valuable items such as artwork.     
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan8}  style={{height:'191px'}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Rapid Storage & Transportation Logistics</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Real-time inventory management and expert loading teams to transport stored items or load specialized carriers.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row></Fade>
          </div>
        </div>
        {/* Frequently Asked Questions */}
        <div className='container-fluid'>
          <div className='ms-4 my-4'>
           <hr className='hrService'/>
           <h2 style={{fontFamily: 'Lato, sans-serif'}}>Frequently Asked Questions</h2>
          </div>
        <Row className='pt-5 FQASerivice'>
          <Col xs={12} md={6} className='serviceFaq'>
          <Fade left>
           <img style={{width:'600px'}} src={welcometruck} alt='welcometruck'/></Fade>
          </Col>
          <Col xs={12} md={6} className=''>
          <Fade right>
            <Accordion sty defaultActiveKey={['0']} alwaysOpen flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato,sans-serif'}}>What delivery services and options do you provide?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Our delivery services offer a variety of shipping options to suit your needs,including standard ground delivery,express air delivery, and freight services for larger or heavier packages across the UK.                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h6 style={{fontFamily: 'lato,sans-serif'}}>What are your delivery timeframes for national packages</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Our delivery timeframes and transit times for national package shipping depend on the origin, destination, and service level selected. We offer next-day air delivery for many major cities, 2-3 day shipping for regional destinations, and 4-7 day ground service coast-to-coast.   
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato,sans-serif'}}> What areas do you deliver packages to?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    We deliver packages to residential, commercial, and PO box addresses across all provinces and territories in United Kingdom, providing comprehensive national delivery coverage.                     </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How much does it cost to ship a package?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Shipping rates and delivery fees are calculated based on package dimensions, weight, and shipping distance. We offer competitive, transparent pricing with no hidden fees. Use our online shipping calculator to get instant quotes.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}> What packing supplies and boxes do you offer for shipping packages?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Our selection of packing supplies includes sturdy cardboard boxes of various sizes, reinforced envelopes, bubble pouches, cushioning material, packing tape, and more to properly protect your shipments. We can also provide custom crating for fragile or oversized items.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How can I get a price quote and schedule a package pickup?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    To get a shipping quote and schedule a pickup, simply enter your package details into our online system or contact our customer service team. We'll provide a price quote, arrange a convenient pickup time and location, and dispatch a courier.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}> Can I track my package delivery status online?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Yes, you can easily track the real-time delivery status of your package using our online tracking platform. Simply enter your tracking number to see the current location and estimated arrival time. </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                </Fade>
            </Col>
        </Row>
       </div>
       {/* TESTIMONIALS */}
       <div className='container'>
   <div className='pt-5 testimonialsNumberSer2'>
    <hr className='hrService'/>
     <p style={{fontFamily: 'Lato, sans-serif', color:'#0924A4'}}>Clients Tales</p>
    </div>
    <div>
         <Row>
          <Col xs={12} md={6} className='testimonialsNumberSer'>
          <Zoom right>
            <h2 style={{fontFamily: 'fantasy',textAlign: '-webkit-match-parent'}}>2000 +</h2>
            <h5 style={{fontFamily:'sans-serif'}}>Satisfied Customers</h5>
            <span>
            We've assisted thousands of people in
relocating across the UK, making their moves smooth
and stress-free with our dedication to outstanding service. </span></Zoom>
          </Col>
          <Col xs={12} md={6}>
          <div className='pb-5 testimonialsCard'>
          <Carousel className='carouselClass'>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src='https://img.freepik.com/free-photo/portrait-smiling-man-outdoors-with-beanie_23-2149256267.jpg?t=st=1708755616~exp=1708759216~hmac=6d2f76bb80e50de8e4cfb8a80ef56eb1450cbe4c63527ea956dbd8d6cc89a429&w=360' alt="Remy Sharp"   />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>George Irven</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in London</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    I had an excellent experience with allukmovers. The team was professional, punctual, and efficient, handling my belongings with care.
                    They made the process smooth and stress-free,
                    quickly moving everything to my self-storage
                    without hassle. Highly recommend them for
                    reliable moving!  </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://cdn.homestars.com/uploaded_images/0367/7899/unnamed_thumb.png" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Taylor</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Leicester</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    They helped me move from Birmingham to
                      Epping with super smooth service. Everything
                      was arranged quickly on short notice, and they
                      were punctual, patient, and helpful all day
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://img.freepik.com/free-photo/front-view-smiley-man-holding-book_23-2149915900.jpg?t=st=1708755547~exp=1708759147~hmac=9a1e15c33e46f9540f8a86594279e9eb2bca62d07c3277e465f682e0667f4d82&w=360" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>David Lucy</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Nottingham</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    They were amazing! I called in the afternoon, and they
                    were ready to pick up within 45 minutes.
                    Excellent service at great prices!</Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
         </Row>
        </div>
  </div>
  
      
      <div  className='pb-5'>
        <MoreService/>
      </div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <a href="/" class="btnGetQuote" style={{padding: '23px' ,position: 'fixed', zIndex: '9999', right: '20px', bottom: '20px', width: '100px', height: '100px'}}>
    {/* <BsFillChatSquareQuoteFill size={40}/> */}
    <h5 style={{fontFamily: 'Lato,sans-serif', fontWeight:'600'}}>Get <br/>Quote</h5>
  </a>
</div>
      {/* Footer */}
      <div className='mt-1'>
        <Footer/>
      </div>
      
    </div>
  )
}

export default StorageServices


