import React,{useState,useEffect} from 'react'
import Header from '../components/Header'
import acvlbanner from '../images/acvl-banner2.png'
import serivePlan from '../images/serivePlan.jpg'
import serivePlan2 from '../images/serivePlan2.jpg'
import serivePlan3 from '../images/serivePlan3.jpg'
import serivePlan4 from '../images/serivePlan4.jpg'
import serivePlan8 from '../images/serivePlan8.jpg'
import serivePlan6 from '../images/serivePlan6.jpg'
import welcometruck from '../images/welcome-truck.png'
import {Fade,Zoom} from 'react-reveal';
import {Row,Col,Card,Button} from 'react-bootstrap';
import { AiFillSafetyCertificate } from "react-icons/ai";
import { IoWalletSharp } from "react-icons/io5";
import { FaTruckFast } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa";
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import Trip from '../images/Trip.jpg'
import Avatar from '@mui/material/Avatar';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Footer from '../components/FooterMain'
import MoreService from '../components/MoreService'
import { ToastContainer, toast } from 'react-toastify';
import { Link,useNavigate } from "react-router-dom";
import Select from 'react-select'
import TrackImage from '../images/TrackImage.png'
import { MdCall } from "react-icons/md";
import {Helmet} from 'react-helmet'

const MoversBritish = () => {
    const [predictions, setPredictions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [predictionsto, setPredictionsto] = useState([]);
    const [inputValueto, setInputValueto] = useState("");
    const [predictionsInput, setpredictionsInput] = useState("");
    const [predictionsInputto, setpredictionsInputto] = useState("");

    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const utmCampaign = searchParams.get('utm_campaign');
      const utmId = searchParams.get('utm_id');
  
      if (utmCampaign) {
        sessionStorage.setItem('utm_campaign', utmCampaign);
      }
  
      if (utmId) {
        sessionStorage.setItem('utm_id', utmId);
      }
    }, []);
    
    
  
    const navigate = useNavigate();
  
    //////////// FROM City API ///////////////
    const fetchDictionFrom = async (inputText) => {
      // if (inputText.length > 0) {
        try {
          const apiUrl = `https://allukmovers.com/ukmoversbackend/api/google-places-autocomplete?inputText=${inputText}`;
  
          const response = await fetch(apiUrl);
          const data = await response.json();
          console.log(data);
          if (data.predictions) {
            const options = data.predictions.map((prediction) => ({
              value: prediction.description,
              label: prediction.description,
            }));
            setPredictions(options);
          }
        } catch (error) {
          console.error("Error fetching predictions:", error);
        }
      // }
    };
  
    const handleSelectChange = (selectedOption) => {
      if (selectedOption && selectedOption.label) {
        setpredictionsInput(selectedOption);
      } else {
        setpredictionsInput(null); // or setpredictionsInput(defaultValue);
      }
    };
  
    useEffect(() => {
      fetchDictionFrom(inputValue);
    }, [inputValue]);
  
    const handleInputChange = (newValue) => {
      setInputValue(newValue);
    };
  
    ///////////// TO City API ///////////////
    const fetchDictionTo = async (inputText) => {
      // if (inputText.length > 1) {
        try {
          const apiUrl = `https://allukmovers.com/ukmoversbackend/api/google-places-autocomplete?inputText=${inputText}`;
  
          const response = await fetch(apiUrl);
          const data = await response.json();
          console.log(data);
          if (data.predictions) {
            console.log("predictions", data.predictions);
            const optionsto = data.predictions.map((prediction) => ({
              value: prediction.description,
              label: prediction.description,
            }));
            setPredictionsto(optionsto);
          }
        } catch (error) {
          console.error("Error fetching predictions:" , error);
        }
      // }
    };
  
    const handleSelectChangeto = (selectedOption) => {
      if (selectedOption && selectedOption.label) {
        setpredictionsInputto(selectedOption);
      } else {
        setpredictionsInputto(null); 
      }
    };
  
    useEffect(() => {
      fetchDictionTo(inputValueto);
    }, [inputValueto]);
  
    const handleInputChangeto = (newValue) => {
      setInputValueto(newValue);
    };
  
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
    const handleNextButtonClick = (event) => {
      event.preventDefault();     
      if (predictionsInput && predictionsInput.label && predictionsInputto && predictionsInputto.label) {
        const dataToSend = {
          from: predictionsInput.label,
          to: predictionsInputto.label,
        };
  
        console.log('NEXT button clicked. Navigating with data:', dataToSend);
  
        // Use navigate to navigate and pass data through state
        navigate('/CalenderForm', { state: { data: dataToSend } });
  
        
  
      } else {  
        toast.warn('locations are required', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          })
      }
    }
    useEffect(() => {
      // Function to get the IP address
      const fetchIpAddress = async () => {
        try {
          // Fetch the IP address from IPify API
          const response = await fetch('https://api.ipify.org?format=json');
          const data = await response.json();
          const ipAddress = data.ip;
  
          // Send the IP address to your API
          await sendIpAddressToApi(ipAddress);
  
        } catch (error) {
          console.error('Error fetching IP address:', error);
        }
      };
  
      // Function to send the IP address to your API
      const sendIpAddressToApi = async (ipAddress) => {
        try {
          const response = await fetch('https://allukmovers.com/acvlbackend/api/ipaddress', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ipaddress: ipAddress }),
          });
  
          if (response.ok) {
            console.log('IP address sent successfully');
          } else {
            console.error('Failed to send IP address');
          }
        } catch (error) {
          console.error('Error sending IP address:', error);
        }
      };
  
      // Call the function to fetch IP and send it to your API
      fetchIpAddress();
  
    }, []); // Empty dependency array ensures this runs only once when the component mounts
  
  return (
    <div>
       <Helmet>
        <title>All United Kingdom Van Lines |moving company in British Columbia | well trained team | reliable movers</title>
        <meta name="description" content="Looking for reliable movers in British Columbia? Our professional team offers expert moving services tailored to your needs. Contact us for a smooth and hassle-free move!" />
      </Helmet>
    <Header/>
    {/* -------------------------- START BANNER --------------------------- */}
    <div className='Banner' style={{backgroundImage: `url(${acvlbanner})`,backgroundSize:'cover',backgroundRepeat: 'no-repeat',width:"100%", height:'350px',backgroundPosition: '105% 0%' }}>
        <div className='container-fluid'>
          <Row>
            <Col xs={7} className='Banner-title' style={{margin:'50px 0px 0px 0px'}}>
          <div  className='bannerHeading' style={{marginLeft: '50px', marginTop:'50px'}}>
              <Fade left>
              <h1 className='bannerHeading' style={{color:'#ffff', fontFamily:'sans-serif',fontWeight: "bold"}}>
               <main class="d-flex">
                <p className='prr mb-0'>Make Moving</p>
                <section class="animation">
                  <div class="first" style={{color:'#FEFFFA'}}><div>Easy</div></div>
                  <div class="second" style={{color:'#FEFFFA'}}><div>Safe And Sound</div></div>
                  <div class="third" style={{color:'#FEFFFA'}}><div>Affordable</div></div>
                </section>
              </main>
               </h1>
              {/* <h1 style={{color:'#ffff', fontFamily:'sans-serif',fontWeight: "bold"}}>Seamless moves across United Kingdom!</h1> */}
              <h5 style={{color:'#ffff', fontFamily:'Lato, sans-serif'}}>Explore Our Exclusive Relocation Offers</h5>
              </Fade>
              {/* <div className='mt-4'>
                <Link to='/Aboutus' ><Button className='button-21'>Read More<RiArrowRightSLine size={19} className='mb-0' /></Button></Link>
              </div> */}
            </div>
            <Fade bottom>
            <div id='Process' className="display-Form" style={{border:'1px solid #262E3E', borderRadius:'10px', marginTop:'25px',backgroundColor:'#262E3E', width:'100%',marginLeft:'50px'}}>
              <div className='container my-4'>
                <Row>
                  <Col xs={5}>
                    <h6 style={{color:'#ffff', fontFamily:'Lato, sans-serif',fontWeight: '600'}}>I am moving from *</h6>
                    <Select 
                        isClearable
                        value={predictionsInput}
                        options={predictions}
                        onInputChange={handleInputChange}
                        onChange={handleSelectChange}
                        noOptionsMessage={() => 'Search Your City'}
                        isRequired placeholder='City' />
                  </Col>
                  <Col xs={5}>
                    <h6 style={{color:'#ffff', fontFamily:'Lato, sans-serif',fontWeight: '600'}}>I am moving to *</h6>
                    <Select 
                     isClearable
                     value={predictionsInputto}
                     options={predictionsto}
                     onInputChange={handleInputChangeto}
                     onChange={handleSelectChangeto}
                     noOptionsMessage={() => 'Search Your City'}
                     isRequired placeholder='City' />
                  </Col>
                  <Col xs={2} style={{marginTop:'20px'}}>
                     {/* <a href='/CalenderForm' ><Button style={{backgroundColor:'#202528', borderColor:'#202528'}}>NEXT</Button></a> */}
                     <Button className='button-70' onClick={handleNextButtonClick} style={{backgroundColor:'#202528', borderColor:'#202528',fontWeight: '600'}}>GET QUOTE</Button>
                  </Col>
                </Row>
              </div>
            </div></Fade>
            </Col>
            <Col xs={5}>
            <Fade right>
              <img className='TrackImge' src={TrackImage} alt='BannerImage' style={{ width:'430px',position: 'absolute',right: '0',marginTop: '3px'}} /></Fade>
            </Col>
          </Row>
        </div>
      </div>
{/* -------------------------- START BannerReponse --------------------------- */}
   <div id='Process' className='Banner BannerReponse' style={{backgroundColor:'#FC4243',backgroundSize:'cover',backgroundRepeat: 'no-repeat',height:'348px',backgroundPosition: '105% 0%' }}>
    <div className='container-fluid'>
      <Row>
        <Col xs={12} className='Banner-title' style={{margin:'100px 0px 0px 0px',zIndex: '999'}}>
      <div className='bannerHeading' style={{marginLeft: '50px'}}>
          <Fade left>
          <h1 className='bannerHeading' style={{color:'#ffff', fontFamily:'Lato, sans-serif',fontWeight: "bold"}}>
          <main class="d-flex">
            <p className='prr'>Make Moving</p>
            <section class="animation">
              <div class="first" style={{color:'#FEFBF0'}}><div>Easy</div></div>
              <div class="second" style={{color:'#FEFBF0'}}><div>Safe And Sound</div></div>
              <div class="third" style={{color:'#FEFBF0'}}><div>Affordable</div></div>
            </section>
          </main>
           </h1>
          <h5 style={{color:'#ffff', fontFamily:'Lato, sans-serif'}}>Explore Our Exclusive Relocation Offers</h5>
          </Fade>
          <div className='mt-4'>
            <a href="tel:+1-833-833-9833" style={{textDecoration:'none'}} ><Button className='button-21'><MdCall size={22} className='me-2 heart text-light'/>Contact Our Team</Button></a>
          </div>
        </div>
        <Fade bottom>
        <div className="display-Form" style={{border:'1px solid #000', borderRadius:'10px', marginTop:'50px',backgroundColor:'#393939', width:'100%',marginLeft:'50px'}}>
          <div className='container my-4'>
            <Row>
              <Col xs={5}>
                <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving from*</h5>
                <Select 
                    isClearable
                    value={predictionsInput}
                    options={predictions}
                    onInputChange={handleInputChange}
                    onChange={handleSelectChange}
                    noOptionsMessage={() => 'Search Your City'}
                    isRequired placeholder='City' />
              </Col>
              <Col xs={5}>
                <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving to*</h5>
                <Select 
                 isClearable
                 value={predictionsInputto}
                 options={predictionsto}
                 onInputChange={handleInputChangeto}
                 onChange={handleSelectChangeto}
                 noOptionsMessage={() => 'Search Your City'}
                 isRequired placeholder='City' />
              </Col>
              <Col xs={2} style={{marginTop:'20px'}}>
                 <Button className='button-70' onClick={handleNextButtonClick} style={{backgroundColor:'#202528', borderColor:'#202528'}}>NEXT</Button>
              </Col>
            </Row>
          </div>
        </div></Fade>
        </Col>
        <Col xs={5}>
        <Fade right>
          <img className='TrackImge' src={TrackImage} alt='BannerImage' style={{position: 'absolute',right: '0'}} /></Fade>
        </Col>
      </Row>
    </div>
  </div> 
{/* ------ Mobile View ----------*/}
  <Fade bottom>
    <div className='container mb-3'>
        <div className="Mobile-Form" style={{border:'1px solid #000', borderRadius:'10px', marginTop:'30px',backgroundColor:'#393939', width:'100%'}}>
          <div className='container my-4'>
            <Row>
              <Col md={5}>
                <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving from*</h5>
                <Select 
                   isClearable
                   value={predictionsInput}
                   options={predictions}
                   onInputChange={handleInputChange}
                   onChange={handleSelectChange}
                   noOptionsMessage={() => 'Search Your City'}
                   isRequired placeholder='From City' />
              </Col>
              <Col md={5} className='mt-3'>
                <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving to*</h5>
                <Select   
                isClearable
                 value={predictionsInputto}
                 options={predictionsto}
                 onInputChange={handleInputChangeto}
                 onChange={handleSelectChangeto}
                 noOptionsMessage={() => 'Search Your City'}
                 isRequired placeholder='To City' />
              </Col>
              <Col md={2} className="d-flex justify-content-center" style={{ marginTop: '25px' }}>
                <Button className='button-71' onClick={handleNextButtonClick} style={{ backgroundColor: '#202528', borderColor: '#202528',fontWeight: '600' }}>GET QUOTE</Button>
              </Col>
            </Row>
          </div>
        </div>
        </div>
  </Fade>
     {/* Circle-Heading */}
    <div className='ms-4 mt-5 pb-4'>
      <Fade right>
      <hr className='hrService'/>
      <h2 style={{fontFamily: 'Lato, sans-serif'}}>Movers in Reading</h2>
      <h5 style={{lineHeight: '1.6',marginRight: '7px',fontFamily:'Lato, sans-serif',fontWeight: '400'}}>Are you searching for movers Reading? All UK Movers is the top choice for relocation services. Our expert movers and packers are dedicated to simplifying your move and handling the challenging tasks on your behalf. We prioritize customer satisfaction and ensure that every relocation need is met with care. Our trained team is punctual and equipped with all the necessary materials to pack and transport your belongings to your new destination safely and efficiently.<br/>
        All UK Movers use quality packing materials to protect your fragile items, ensuring a damage-free relocation experience. If you’re planning a move from Reading, choose All UK Movers for a seamless, stress-free journey. We also offer complimentary quotes to help you plan your budget. With just a few clicks, access multiple no-obligation quotes from moving companies in Reading and streamline your arrangements instantly! Contact us today to learn more about our services and receive your free quotes.
        </h5>
      </Fade>
    </div>
    {/* Circle */}
    <div className='container my-5'>
    <Fade left>
      <Row className=''>
        <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
         <div style={{borderRadius:'50%', border:'3px solid red',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center',}}>
          <AiFillSafetyCertificate size={90}/>
         </div>
         <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Safe and Secured</h5>
        </Col>
        <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
         <div style={{borderRadius:'50%', border:'3px solid red',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
          <IoWalletSharp size={80}/>
         </div>
         <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Affordable</h5>
        </Col>
        <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
         <div style={{borderRadius:'50%', border:'3px solid red',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
          <FaBusinessTime size={80}/>
         </div>
         <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Efficient and Reliable</h5>
        </Col>
        <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
         <div style={{borderRadius:'50%', border:'3px solid red',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
          <FaTruckFast size={80}/>
         </div>
         <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Trained Movers</h5>
        </Col>
      </Row></Fade>
    </div>
    {/* Detailed List of Services Included */}
    <div className='pt-4'>
    <Fade left>
      <div className='container'>
      <hr className='hrService '/>
      <h2 style={{fontFamily: 'Lato, sans-serif', marginRight: '1px'}}>Detailed List of Services Included</h2>
      </div></Fade>
      <div className='px-3 pt-4'>
      <Fade right>
        <Row className='pb-5'>
          <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
          <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
              <Card.Img variant="top" src={serivePlan} style={{height:'191px'}} />
              <Card.Body>
                <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Custom Relocation Plans</Card.Title>
                <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                Tailored plans handling logistics, needs assessment, quotes, packing, storage, equipment, manpower, vehicles for seamless local moves based on home size and possessions.
                </Card.Text></Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
          <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
              <Card.Img variant="top" src={serivePlan2}  style={{height:'191px'}}/>
              <Card.Body>
              <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Packing Supplies</Card.Title>
                <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                 Affordable Toronto moving kits with sturdy boxes, bubble wrap, tape, specialty paper, and quality materials to safely DIY pack fragiles, furniture, and appliances.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
          <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
              <Card.Img variant="top" src={serivePlan3}  style={{height:'191px',}} />
              <Card.Body>
              <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Furniture Disassembly & Reassembly</Card.Title>
                <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                Professional movers carefully disassemble and reassemble desks, tables, cabinets, and furniture to navigate doorways.
              </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row> 
        <Row className='pb-5'>
          <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
          <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
              <Card.Img variant="top" src={serivePlan4} style={{height:'191px'}} />
              <Card.Body>
                <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Loading and Unloading</Card.Title>
                <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                Complete loading help packing truck and van cargo areas optimally and secure unloading at new local address. Handle heavy lifting while directing room-by-room placement.   </Card.Text>         
                 </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
          <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
              <Card.Img variant="top" src={serivePlan6}  style={{height:'191px'}} />
              <Card.Body>
              <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Protection of Belongings </Card.Title>
                <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                Preserve cherished possessions with top packing techniques and high-grade materials like water-resistant cartons, cushioning, and dust shields tailored to fragile vs. durable items.
                 </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
          <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
              <Card.Img variant="top" src={serivePlan8}  style={{height:'191px'}} />
              <Card.Body>
              <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Timely Transportation</Card.Title>
                <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                Punctual pickup and delivery when moving locally. Confirmed time windows and status updates for seamless transitions between old and new GTA homes.              </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row></Fade>
      </div>
    </div>
    {/* Frequently Asked Questions */}
    <div className='container-fluid'>
      <div className='ms-4 my-4'>
       <hr className='hrService'/>
       <h2 style={{fontFamily: 'Lato, sans-serif'}}>Frequently Asked Questions</h2>
      </div>
    <Row className='pt-5 FQASerivice'>
      <Col xs={12} md={6} className='serviceFaq'>
      <Fade left>
       <img style={{width:'600px'}} src={welcometruck} alt='welcometruck'/></Fade>
      </Col>
      <Col xs={12} md={6} className=''>
      <Fade right>
        <Accordion sty defaultActiveKey={['0']} alwaysOpen flush>
            <Accordion.Item eventKey="0">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What areas do you provide local and long-distance moving services in?</h6></Accordion.Header>
                <Accordion.Body>
                 We provide professional local and long-distance moving services across major cities in Ontario, including Toronto, Mississauga, Brampton, Oakville, Burlington, and surrounding areas. Our experienced movers can handle both local residential moves within the Greater Toronto Area as well as long-distance relocations across United Kingdom.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What sizes of trucks do you use for local moves? </h6></Accordion.Header>
                <Accordion.Body>
                For local moves, we utilize a fleet of well-maintained trucks ranging in size from 16-foot to 26-foot trucks, ensuring we have the appropriate vehicle capacity to efficiently move household goods of any size.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>Are your local movers fully licensed and insured?</h6></Accordion.Header>
                <Accordion.Body>
                Yes, our local movers offer comprehensive packing and unpacking services to make your move as stress-free as possible. Our team can carefully pack and wrap your belongings using high-quality materials, and then unpack and place items in their designated rooms at your new location.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What safety protocols do you follow during local moves?</h6></Accordion.Header>
                <Accordion.Body>
                 Our local movers are fully licensed and insured professionals, ensuring your peace of mind during the entire moving process. We carry general liability and workers' compensation insurance to protect both our clients and our staff.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How are your hourly rates and minimums determined for local moving? </h6></Accordion.Header>
                <Accordion.Body>
                We follow strict safety protocols during local moves, including maintaining social distancing, wearing personal protective equipment (PPE), and regularly sanitizing our trucks and equipment to ensure a safe and healthy environment for both our clients and our team.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What extra moving services do you offer besides loading/unloading? </h6></Accordion.Header>
                <Accordion.Body>
                 Our hourly rates for local moving services are based on the number of movers required, the size of the moving truck, and any additional services requested. We also have a minimum charge to cover overhead costs, but our rates are always clearly communicated upfront, with no hidden fees.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How can I get a binding moving quote for my local relocation?</h6></Accordion.Header>
                <Accordion.Body>
                In addition to loading and unloading services, we offer a range of specialty moving services, including piano moving, furniture disassembly and reassembly, and appliance disconnection and reconnection, to ensure a seamless and stress-free relocation.
                </Accordion.Body>
            </Accordion.Item>
            </Accordion></Fade>
        </Col>
    </Row>
   </div>
   {/* TESTIMONIALS */}
       <div className='container'>
       <div className='pt-5 testimonialsNumberSer2'>
        <hr className='hrService'/>
         <p style={{fontFamily: 'Lato, sans-serif', color:'#0924A4'}}>Happy Customers</p>
        </div>
        <div>
         <Row>
          <Col xs={12} md={6} className='testimonialsNumberSer'>
          <Zoom right>
            <h2 style={{fontFamily: 'fantasy',textAlign: '-webkit-match-parent'}}>2000 +</h2>
            <h5 style={{fontFamily:'sans-serif'}}>Satisfied Customers</h5>
            <span>
            We've assisted thousands of people in relocating across the UK, making their moves smooth and stress-free with our dedication to outstanding service.</span></Zoom>
          </Col>
          <Col xs={12} md={6}>
          <div className='pb-5 testimonialsCard'>
          <Carousel className='carouselClass'>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src='https://img.freepik.com/free-photo/portrait-smiling-man-outdoors-with-beanie_23-2149256267.jpg?t=st=1708755616~exp=1708759216~hmac=6d2f76bb80e50de8e4cfb8a80ef56eb1450cbe4c63527ea956dbd8d6cc89a429&w=360' alt="Remy Sharp"   />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>George Irven</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in London</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    I had an excellent experience with allukmovers. The team was professional, punctual, and efficient, handling my belongings with care.
                    They made the process smooth and stress-free,
                    quickly moving everything to my self-storage
                    without hassle. Highly recommend them for
                    reliable moving!  </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://cdn.homestars.com/uploaded_images/0367/7899/unnamed_thumb.png" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Taylor</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Leicester</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    They helped me move from Birmingham to
                      Epping with super smooth service. Everything
                      was arranged quickly on short notice, and they
                      were punctual, patient, and helpful all day
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://img.freepik.com/free-photo/front-view-smiley-man-holding-book_23-2149915900.jpg?t=st=1708755547~exp=1708759147~hmac=9a1e15c33e46f9540f8a86594279e9eb2bca62d07c3277e465f682e0667f4d82&w=360" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>David Lucy</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Nottingham</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    They were amazing! I called in the afternoon, and they
                    were ready to pick up within 45 minutes.
                    Excellent service at great prices!</Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
         </Row>
        </div>
      </div>
  
  <div  className='pb-5'>
    <MoreService/>
  </div>
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
<a href="/" class="btnGetQuote" style={{padding: '23px' ,position: 'fixed', zIndex: '9999', right: '20px', bottom: '20px', width: '100px', height: '100px'}}>
{/* <BsFillChatSquareQuoteFill size={40}/> */}
<h5 style={{fontFamily: 'Lato,sans-serif', fontWeight:'600'}}>Get <br/>Quote</h5>
</a>
</div>
  {/* Footer */}
  <div className='mt-1'>
    <Footer/>
  </div>
  
</div>
  )
}

export default MoversBritish

