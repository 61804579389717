import React,{useState, useEffect} from 'react'
import Header from '../components/Header'
import {Col,Row} from 'react-bootstrap';
import AboutImage from '../images/about2.jpg'
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import TrackChangesRoundedIcon from '@mui/icons-material/TrackChangesRounded';
import {Zoom,Fade} from 'react-reveal';
import Footer from '../components/FooterMain';
import {useSpring, animated} from 'react-spring';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function Number ({n}) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 6000,
    config: { mass: 1, tension: 20, friction: 10 },
})
return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}  


const Aboutus = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const utmCampaign = searchParams.get('utm_campaign');
      const utmId = searchParams.get('utm_id');
  
      if (utmCampaign) {
        sessionStorage.setItem('utm_campaign', utmCampaign);
      }
  
      if (utmId) {
        sessionStorage.setItem('utm_id', utmId);
      }
    }, []);

  return (
    <div>
      <Header/>
      <div style={{background: 'linear-gradient(to top, #ffffff 0%, #edf4fe 74%, #ffe7e5 100%)',position: 'relative',padding: '60px 0 80px',overflow: 'hidden'}}>
        <Fade bottom>
        <div style={{justifyContent:'center', display:'flex',alignItems:'center'}}>
            <h1 style={{fontSize: '55px',color: '#000',lineHeight: '1.2',letterSpacing: '-2px', fontFamily:'Lato, sans-serif'}}>About Us</h1>
        </div>
        <div style={{justifyContent:'center', display:'flex',alignItems:'center'}}>
        <span style={{margin:'0% 20%',textAlign: 'center',lineHeight: '35px', fontFamily:'Lato, sans-serif'}}>
          ACVL offers customized relocations across United Kingdom with personalized care and attention. 
          Specialists securely pack, transport, and unpack using top techniques. 2000+ satisfied customers.
           Trust us for hassle-free moves tailored to your needs. We handle the details so that you can move 
           forward confidently.
                </span>
        </div></Fade>
      </div>
      {/* About Our Company */}
      <div className='container mb-5' style={{marginTop:'70px'}}>
        <Row>
          <Col xs={12} md={6} className='AboutImage2'>
          <Fade left>
             <img src={AboutImage} alt='aboutImage' style={{width:'400px'}}/></Fade>
          </Col>
          <Col xs={12} md={6}>
          <Fade right>
            <h3 style={{fontWeight: '700',margin: '0',lineHeight: '1.4', fontFamily:'Inter, sans-serif', marginLeft:'10px'}}>About Our Company</h3>
            <div className='pt-4'>
              <Box sx={{ bgcolor: 'background.paper'}}>
              <AppBar position="static" style={{ backgroundColor: '#FC4243', borderRadius: '50px' }}>
                <Tabs 
                  value={value}
                  onChange={handleChange}
                  indicatorColor="red"
                  textColor="inherit"
                  variant="fullWidth">

                  <Tab className='zoom' icon={<TrackChangesRoundedIcon />} label="Our Mission" {...a11yProps(0)} />
                  <Tab className='zoom' icon={<AdsClickIcon/>} label="Our vision" {...a11yProps(1)} />
                  <Tab className='zoom' icon={<FlagCircleRoundedIcon/>} label="Our Goal" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                 <p style={{lineHeight: '2',paddingTop: '10px',textAlign: 'justify', color:'#666'}}>  Whether you're a small start-up or a large enterprise, our range of services is designed to cater to your unique needs. From web design and CMS development to mobile application development and quality engineering, we have the expertise and resources to deliver innovative solutions that exceed your expectations. Enable your business strategy and deliver new capabilities with a defined technology roadmap and high-performing agile software delivery capability by evolving your skills, practices, structures and team cultures.</p>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                 <p style={{lineHeight: '2',paddingTop: '10px',textAlign: 'justify',color:'#666'}}> Our early focus on new opportunities has allowed us to switch to the next level with ease and has helped us emerge as a one-stop for all technology solutions that the business world requires. We are privileged to have a highly talented and passionate workforce that puts in a massive amount of work, thus guaranteeing our success.  </p>              
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <p style={{lineHeight: '2',paddingTop: '10px',textAlign: 'justify',color:'#666'}}> We understand the importance of staying ahead of the curve in today's rapidly evolving digital landscape. Therefore, we continuously invest in our people, processes and technologies to ensure that we provide the best possible services to our clients. We pride ourselves on our ability to adapt to changing market trends and customer requirements, making us a reliable partner for businesses looking to stay ahead of the competition. </p>
                 </TabPanel>
              </SwipeableViews>
              </Box>
            </div></Fade>
          </Col>
        </Row>
      </div>

      {/* Our Journey Started*/}
      <section style={{position: 'relative',padding: '30px 0',backgroundColor:'#F1F0F7'}}>
        <div className='container'>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h3 style={{ fontWeight: '700', margin: '0', lineHeight: '1.4', fontSize: '1.75rem', fontFamily: 'Inter, sans-serif' }}>Our Journey Started</h3>
            <p style={{ fontSize: '12px', lineHeight: '1.7', margin: '0',fontFamily:'Inter, sans-serif' }}>More than 2000+ client trust and choose ACVL</p>
        </div>

        {/* Journey Start */}

        <div>
        <div class="conatinerCSS">
          <div class="timeline-block timeline-block-right">
            <div class="marker"></div>
             <div class="timeline-content">
              <Fade right>
                <h3>2009</h3>
                <span style={{color:'#FC4243',fontFamily:'Lato, sans-serif'}}>Built New Team</span>
                <p style={{fontFamily:'Lato, sans-serif'}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.</p>
              </Fade>
             </div>
          </div>
          <div class="timeline-block timeline-block-left">
            <div class="marker"></div>
            <div class="timeline-content">
            <Fade left>
                <h3>2011</h3>
                <span style={{color:'#FC4243',fontFamily:'Lato, sans-serif'}}>New Brand Identity</span>
                <p style={{fontFamily:'Lato, sans-serif'}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.</p>
            </Fade>
            </div>
          </div>
          <div class="timeline-block timeline-block-right">
            <div class="marker"></div>
            <div class="timeline-content">
            <Fade right>
                <h3>2015</h3>
                <span style={{color:'#FC4243',fontFamily:'Lato, sans-serif'}}>Foundation Of Business</span>
                <p style={{fontFamily:'Lato, sans-serif'}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.</p>
            </Fade>
            </div>
          </div>
          <div class="timeline-block timeline-block-left">
            <div class="marker"></div>
            <div class="timeline-content">
              <Fade left>
                <h3>2017</h3>
                <span style={{color:'#FC4243',fontFamily:'Lato, sans-serif'}}>Competitiveness</span>
                <p style={{fontFamily:'Lato, sans-serif'}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.</p>
                </Fade>
            </div>
          </div>
          <div class="timeline-block timeline-block-right">
            <div class="marker"></div>
            <div class="timeline-content">
            <Fade right>
                <h3>2021</h3>
                <span style={{color:'#FC4243',fontFamily:'Lato, sans-serif'}}>Leading Company</span>
                <p style={{fontFamily:'Lato, sans-serif'}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.</p>
            </Fade>
            </div>
          </div>
          </div>
        </div>
        </div>
      </section>
           {/* Our Reputation */}
           <div className='my-5'>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h3 style={{ fontWeight: '700', margin: '0', lineHeight: '1.4', fontSize: '1.75rem', fontFamily: 'Inter, sans-serif'}}>Our Achievements </h3>
          </div>
        {/* Team members */}
        {/* <Zoom right>
          <div className='container my-5'>
            <Row>
            <Col className='text-center d-flex flex-column align-items-center'>
              <div className="image-circle">
                <img className='imgesr' src="https://www.w3schools.com/howto/img_avatar.png" alt="Circle" />
              </div>
              <h5 style={{paddingTop:'10px',fontFamily: 'Inter, sans-serif'}}>Mohammed</h5>
              <small style={{ fontSize: '13px',color: '#888'}}>Founder & CEO</small>
              <div style={{display:'flex', marginTop:'10px'}}>
                <FacebookRoundedIcon className='iconHover' style={{marginRight:'10px', cursor:'pointer'}}/>
                <TwitterIcon className='iconHover' style={{marginRight:'10px', cursor:'pointer'}}/>
                <LinkedInIcon className='iconHover' style={{ cursor:'pointer'}}/>
              </div>
            </Col>
            <Col className='text-center d-flex flex-column align-items-center'>
              <div className="image-circle">
                <img className='imgesr' src="https://www.w3schools.com/howto/img_avatar.png" alt="Circle" />
              </div>
              <h5 style={{paddingTop:'10px',fontFamily: 'Inter, sans-serif'}}>Mohammed</h5>
              <small style={{ fontSize: '13px',color: '#888'}}>CTO</small>
               <div style={{display:'flex', marginTop:'10px'}}>
                <FacebookRoundedIcon className='iconHover' style={{marginRight:'10px',cursor:'pointer'}}/>
                <TwitterIcon className='iconHover' style={{marginRight:'10px',cursor:'pointer'}}/>
                <LinkedInIcon className='iconHover' style={{cursor:'pointer'}}/>
              </div>
            </Col>
            <Col className='text-center d-flex flex-column align-items-center'>
              <div className="image-circle">
                <img className='imgesr' src="https://www.w3schools.com/howto/img_avatar.png" alt="Circle" />
              </div>
              <h5 style={{paddingTop:'10px',fontFamily: 'Inter, sans-serif'}}>Mohammed</h5>
              <small style={{ fontSize: '13px',color: '#888'}}>COO</small>
               <div style={{display:'flex', marginTop:'10px'}}>
                <FacebookRoundedIcon className='iconHover' style={{marginRight:'10px',cursor:'pointer'}}/>
                <TwitterIcon className='iconHover' style={{marginRight:'10px',cursor:'pointer'}}/>
                <LinkedInIcon className='iconHover' style={{cursor:'pointer'}}/>
              </div>
            </Col>
            <Col className='text-center d-flex flex-column align-items-center'>
              <div className="image-circle">
                <img className='imgesr' src="https://www.w3schools.com/howto/img_avatar.png" alt="Circle" />
              </div>
              <h5 style={{paddingTop:'10px',fontFamily: 'Inter, sans-serif'}}>Mohammed</h5>
              <small style={{ fontSize: '13px',color: '#888'}}>Vice President</small>
               <div style={{display:'flex', marginTop:'10px'}}>
                <FacebookRoundedIcon className='iconHover' style={{marginRight:'10px',cursor:'pointer'}}/>
                <TwitterIcon className='iconHover' style={{marginRight:'10px',cursor:'pointer'}}/>
                <LinkedInIcon className='iconHover' style={{cursor:'pointer'}}/>
              </div>
            </Col>
            </Row>
          </div></Zoom> */}
        {/* Year of Exp */}
        <div className='container py-5'>
          <Row>
            <Col xs={12} md={4}>
              <div style={{display:'flex'}}>
              <h1 style={{ fontSize: '45px', fontWeight: '700', color: '#FC4243', display: 'flex' }}>
                 <Number n={20} /><span style={{ marginLeft: '10px' }}>+</span></h1>    
                             <h6 className='webViewCoutAbout' style={{ fontSize: '20px',color: '#000',lineHeight: '1.5',textTransform: 'capitalize',padding: '0px 25px', fontFamily:'Lato, sans-serif'}}>Years <br/>of Experience</h6>
                             {/* Mobile View */}
                             <h6 className='mobileViewCoutAbout' style={{ fontSize: '20px',color: '#000',lineHeight: '1.5',textTransform: 'capitalize',padding: '15px 25px', fontFamily:'Lato, sans-serif'}}>Years  of  Experience</h6>
                <div className='aboutCountLine' style={{borderLeft: '1px solid #cacaca',height: '70px', textAlign:'end'}}></div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div style={{display:'flex'}}>
              <h1 style={{ fontSize: '45px', fontWeight: '700', color: '#FC4243', display: 'flex' }}>
            <Number n={1500} /><span style={{ marginLeft: '10px' }}>+</span></h1>                
            <h6 className='webViewCoutAbout' style={{ fontSize: '20px',color: '#000',lineHeight: '1.5',textTransform: 'capitalize',padding: '0px 25px',fontFamily:'Lato, sans-serif'}}>Delivery <br/>on time</h6>
              {/* Mobile View */}
              <h6 className='mobileViewCoutAbout' style={{ fontSize: '20px',color: '#000',lineHeight: '1.5',textTransform: 'capitalize',padding: '15px 25px',fontFamily:'Lato, sans-serif'}}>Delivery on time</h6>
                <div  className='aboutCountLine' style={{borderLeft: '1px solid #cacaca',height: '70px', textAlign:'end'}}></div>

              </div>
            </Col>
            <Col xs={12} md={4}>
              <div style={{display:'flex'}}>
              <h1 style={{ fontSize: '45px', fontWeight: '700', color: '#FC4243', display: 'flex' }}>
                <Number n={2000} />
                <span style={{ marginLeft: '10px' }}>+</span></h1>                
              <h6 className='webViewCoutAbout'  style={{ fontSize: '20px',color: '#000',lineHeight: '1.5',textTransform: 'capitalize',padding: '0px 25px',fontFamily:'Lato, sans-serif'}}>Satisfied <br/>Clients</h6>
                {/* Mobile View */}
                <h6 className='mobileViewCoutAbout' style={{ fontSize: '20px',color: '#000',lineHeight: '1.5',textTransform: 'capitalize',padding: '15px 25px',fontFamily:'Lato, sans-serif'}}>Satisfied Clients</h6>
              </div>
            </Col>
          </Row>
        </div>
        </div>
        <div>
          <Footer />
        </div>
    </div>
  )
}

export default Aboutus
