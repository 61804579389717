import React, { useState } from 'react';
import { TextField, Box } from '@mui/material';

const UKPhoneValidation = () => {
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const validateUKPhoneNumber = (number) => {
    // Regular expression for UK mobile numbers 
    // Includes 07, 020, 01 prefixes 
    const regex = /^(?:(?:00|\\+)44|0)(?:[1-9]\\d{9}|[789]\\d{8}|20[789]\\d{7}|1[1-9]\\d{8})$/; 
    return regex.test(number);
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    setPhone(input);

    if (!validateUKPhoneNumber(input)) {
      setPhoneError('Please enter a valid UK phone number.'); 
    } else {
      setPhoneError('');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        maxWidth: '400px',
        margin: '0 auto',
      }}
    >
      <TextField
        type="tel"
        fullWidth
        label="Enter Phone Number*"
        placeholder="e.g., +447911123456, 07911123456"
        variant="outlined"
        value={phone}
        onChange={handlePhoneChange}
        error={phoneError !== ''}
        helperText={phoneError}
      />
    </Box>
  );
};

export default UKPhoneValidation;