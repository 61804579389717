import React from 'react'
import Headers from '../components/Header';
import {Zoom,Fade} from 'react-reveal';
import FooterMain from '../components/FooterMain';


const PrivacyPolicy = () => {
  return (
    <div>
      <Headers/>
      <div
      style={{ background: 'linear-gradient(to top, #ffffff 0%, #edf4fe 74%, #ffe7e5 100%)', position: 'relative',
      padding: '60px 0 80px', overflow: 'hidden'}}>
      <Fade bottom>
        <div className='container teams-container'>
          <div style={{ justifyContent: 'start', display: 'flex', alignItems: 'start' }}>
            <h1
              style={{  fontSize: '55px', color: '#000', lineHeight: '1.2',letterSpacing: '-2px',fontFamily: 'Lato, sans-serif'}} > Terms and conditions
            </h1>
          </div>
          <div style={{ justifyContent: 'start', display: 'flex', alignItems: 'start' }}>
            <h5
              style={{ textAlign: 'start', lineHeight: '35px', fontFamily: 'Lato, sans-serif' }} >
              Welcome to <a href="https://www.allukmovers.com/">all United Kingdom van lines.</a> By using our moving and packaging services, you agree to the following terms and conditions. These terms govern all aspects of our services, including bookings, payments, liabilities, and more.
            </h5>
          </div>
          
          <div>
            <h2 className="teams-heading">Services We Offer</h2>
            <h5>We provide a comprehensive range of moving and packaging services, including:</h5>
            <ul className="teams-list">
              <li>Residential and commercial moving</li>
              <li>Packing and unpacking</li>
              <li>Storage options</li>
              <li>Handling of specialty items (e.g, pianos, antiques)</li>
              <li>Long-distance relocation</li>
            </ul>

            <h2 className="teams-heading">The Booking and Payment Process</h2>
            <ul className="teams-list">
              <li>
                <strong>Confirmation:</strong> Your booking is confirmed once we receive a non-refundable deposit. We’ll send a confirmation outlining the agreed services, dates, and costs.
              </li>
              <li>
                <strong>Payment Methods:</strong> Payments can be made via credit card, debit card, or bank transfer. 50% of payment is due on pick-up, and the final payment is due before delivery.
              </li>
              <li>
                <strong>Deposit Requirement:</strong> A deposit of $200 is required at the time of booking to secure your moving date. This deposit is non-refundable and will be adjusted to your final bill value.
              </li>
            </ul>

            <h2 className="teams-heading">Cancellation and Rescheduling Policy</h2>
            <ul className="teams-list">
              <li>
                <strong>Cancellation:</strong> If you need to cancel, please inform us at least 3 days before your move. Late cancellations will result in a cancellation fee equivalent to the amount of loss incurred by the company.
              </li>
              <li>
                <strong>Rescheduling:</strong> You may reschedule your move without penalty if done at least 3 days prior to the scheduled date. Late rescheduling may incur additional fees.
              </li>
            </ul>

            <h2 className="teams-heading">Liability and Insurance Coverage</h2>
            <ul className="teams-list">
              <li>
                <strong>Basic Coverage:</strong> We provide basic liability coverage at no extra cost, which covers $0.60 per pound per item.
              </li>
              <li>
                <strong>Full-Value Protection:</strong> For additional protection, you can opt for full-value coverage. Please inquire about details and costs.
              </li>
            </ul>

            <h2 className="teams-heading">Client Responsibilities</h2>
            <ul className="teams-list">
              <li>
                <strong>Packing:</strong> If you handle your own packing, you are responsible for ensuring all items are securely packed. We are not liable for damage to improperly packed items.
              </li>
              <li>
                <strong>Access:</strong> Ensure clear and safe access to the property on moving day, including parking, elevator bookings, and special instructions.
              </li>
              <li>
                <strong>Inventory:</strong> It’s your responsibility to provide an accurate list of items to be moved. Additional items may result in extra charges.
              </li>
            </ul>

            <h2 className="teams-heading">Storage Services</h2>
            <ul className="teams-list">
              <li>
                <strong>Duration:</strong> We offer both short-term and long-term storage. Fees are billed on a monthly basis.
              </li>
              <li>
                <strong>Access:</strong> To access stored items, please provide 12 hours notice. Access fees may apply.
              </li>
              <li>
                <strong>Abandonment:</strong> If storage fees are unpaid for 3 consecutive months, items will be considered abandoned and may be disposed of or sold.
              </li>
            </ul>

            <h3 className="teams-heading">Dispute Resolution</h3>
            <p>
              Any disputes related to our services will be settled through arbitration, following the laws of Your Province. Each party is responsible for its own arbitration costs.
            </p>

            <h3 className="teams-heading">Privacy Policy</h3>
            <p>
              We respect your privacy. We collect only the personal information necessary to provide our services and will not share your data with third parties, except as required by law.
            </p>

            <h3 className="teams-heading">Changes to the Terms</h3>
            <p>
              We may update these Terms and Conditions at any time. Changes will take effect immediately upon posting on our website or upon notification.
            </p>

            <h3 className="teams-heading">Governing Law</h3>
            <p>
              These Terms and Conditions are governed by the laws of United Kingdom and the province of Your Province.
            </p>

            <h3 className="teams-heading">Contact Information</h3>
            <p>
              For questions or concerns regarding these Terms and Conditions, please <a href='tel: 1-833-833-9833'>contact us at 1-833-833-9833.</a>
            </p>
          </div>
        </div>
      </Fade>
      </div>
    <FooterMain/>
    </div>
  )
}

export default PrivacyPolicy
